@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;

        --radius: 0.5rem;

        --radix-select-content-z-index: 60;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
    }

    body {
        @apply bg-zinc-100 text-foreground;
    }

    html,
    body {
        @apply h-full w-full p-0 m-0;
    }

    #__next {
        @apply h-full;
    }

    .content {
        a {
            @apply underline;
        }

        .profession a {
            @apply text-black !important;
        }

    }

    a:hover {
        @apply underline;
    }

    *,
    *::before,
    *::after {
        @apply box-border;
    }

    h1 {
        @apply text-4xl font-bold mt-4 mb-2;
    }

    h2 {
        @apply text-3xl font-bold mt-4 mb-2;
    }

    h3 {
        @apply text-2xl font-bold mt-4 mb-2;
    }

    h4 {
        @apply text-xl font-bold mt-4 mb-2;
    }

    h5 {
        @apply text-lg font-bold mt-4 mb-2;
    }

    h6 {
        @apply text-base font-bold mt-4 mb-2;
    }

    p {
        @apply my-4 text-base leading-relaxed;
    }

    ul,
    ol {
        @apply pl-5 my-4 list-disc leading-4;
    }

    ol {
        @apply list-decimal;
    }

    li {
        @apply mb-2;
    }

    img {
        @apply max-w-full h-auto;
    }

    .product-main-image img {
        @apply w-[unset] h-[unset] max-w-[unset] max-h-[unset];
    }
}